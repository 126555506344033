import { PlayerService } from './../../services/player.service';
import { EventsService } from './../../services/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from '../../../assets/constants';
import { Workout, WorkoutSequenceBlock, WorkoutTag, PramacloudData } from './../../interfaces/workout';

import { Component, OnInit, Input, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

import { Plugins } from '@capacitor/core'
const { CapacitorKeepScreenOn } = Plugins
const { App } = Plugins;

// import { BleService } from 'src/app/services/ble.service';
// import { BluetoothCore, BrowserWebBluetooth, ConsoleLoggerService } from '@manekinekko/angular-web-bluetooth';
import { Subscription } from 'rxjs';

// export const bleCore = (b: BrowserWebBluetooth, l: ConsoleLoggerService) => new BluetoothCore(b, l);
// export const bleService = (b: BluetoothCore) => new BleService(b);

// make sure we get a singleton instance of each service
// const PROVIDERS = [{
//   provide: BluetoothCore,
//   useFactory: bleCore,
//   deps: [BrowserWebBluetooth, ConsoleLoggerService]
// }, {
//   provide: BleService,
//   useFactory: bleService,
//   deps: [BluetoothCore]
// }];


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  // providers: PROVIDERS

})
export class PlayerComponent implements OnInit, OnDestroy {

  @Input() workout: Workout;
  @Input() computedSequenceList: WorkoutTag[];

  @ViewChild('musicChannel') musicChannel;

  docElement = document.documentElement; // Para realizar el full screen

  // public workout: Workout;
  public mainTime: number;

  private mainTimer;
  public mainTimerRunning: boolean;
  public workoutStarted: boolean;
  public workoutFinished: boolean;

  public currentBlocks: WorkoutSequenceBlock[];
  public nextBlock: WorkoutSequenceBlock;
  public previousBlock: WorkoutSequenceBlock;

  public currentMusicBlocks: WorkoutSequenceBlock[];
  public currentPlaylist: any[];

  public currentTriggerBlocks: WorkoutSequenceBlock[];

  public mainMusic = new Audio();
  public triggerChannel = new Audio();

  public mainVolume: number;

  public quitQuestion: boolean;

  public currentComputedSequence: WorkoutTag; // Sesión actual

  public fullScreen: boolean;

  // public circleColor = '#60B03D';
  public restColor = constants.circleColors.ColorGreen;
  public timeInnerCircleColor = constants.circleColors.ColorLightGray;
  public timeOuterCircleColor = constants.circleColors.ColorDarkGray;
  public HRInnerCircleColor = constants.circleColors.ColorLightGray;
  public HROuterCircleColor = constants.circleColors.HRColorDarkGray;

  public heartRate = { value: 0 };

  public sendDataObject: PramacloudData;

  public userMaxBpm: number;

  // value = null;
  // heartRateValue = null;
  // heartRatePercentage = null;
  // heartRateDevice: BluetoothDevice;
  // mode = 'determinate';
  // color = 'primary';
  // valuesSubscription: Subscription;
  // streamSubscription: Subscription;
  // deviceSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private events: EventsService, public globalService: GlobalService, private playerService: PlayerService) {
    //this.workout = this.route.snapshot.data.workout;

    // EventListeners para manejar ESC de salida de FullScreen
    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', this.fullScreenExitHandler, false);
      document.addEventListener('mozfullscreenchange', this.fullScreenExitHandler, false);
      document.addEventListener('fullscreenchange', this.fullScreenExitHandler, false);
      document.addEventListener('MSFullscreenChange', this.fullScreenExitHandler, false);
    }

    // Obtenemos userMaxBpm
    let sessionInfo = JSON.parse(localStorage.getItem(constants.keySession));
    if (sessionInfo) {
      this.userMaxBpm = sessionInfo.userMaxBpm ? sessionInfo.userMaxBpm : 0;
    }

    // bluetoothService.config({
    //   decoder: (value: DataView) => value.getInt8(1),
    //   service: 'heart_rate',
    //   characteristic: 'heart_rate_measurement'
    // });

  }

  ngOnInit(): void {
    
    this.resetWorkout();
    if (this.globalService.isMobile) {
      CapacitorKeepScreenOn.enable();
    }
    this.startWorkout(); // Llamada inicial para que no espere 1 segundo
    this.processBlocks(false);

    // Activado si queremos HR
    //this.pauseMainTimer();
    //this.workoutStarted = false;

    this.sendInitDataToPramacloud();

    // this.getDeviceStatus();
    // this.streamSubscription = this.bluetoothService.stream()
    //   .subscribe({
    //     next: this.hrUpdateValue.bind(this),
    //     error: this.hrHasError.bind(this)
    //   });

    // Prevenimos botón Back
    App.addListener('backButton', () => {
      this.pauseMainTimer();
    });
  }


  ngOnDestroy(): void {
    this.mainMusic.pause();
    this.triggerChannel.pause();

    clearInterval(this.mainTimer);
    if (this.globalService.isMobile) {
      CapacitorKeepScreenOn.disable();
    }

    // this.valuesSubscription.unsubscribe();
    // this.deviceSubscription.unsubscribe();
    // this.streamSubscription.unsubscribe();
  }

  private updateMainTimeController() {
    if (this.mainTimer === undefined) {
      //this.processBlocks(false); // Llamada inicial para que no espere 1 segundo
      this.mainTimer = setInterval(() => this.processBlocks(), 1000);
    }
  }


  public startWorkout() {
    // this.currentComputedSequence = this.computedSequenceList[0];    
    this.workoutStarted = true;
    this.updateMainTimeController();
    this.sendInitDataToPramacloud();
    this.resumeMainTimer();
  }

  private resetWorkout() {
    this.mainTime = 0;
    this.currentBlocks = [];
    this.currentMusicBlocks = [];
    this.currentTriggerBlocks = [];
    this.mainTimerRunning = true;
    this.workoutFinished = false;
    this.quitQuestion = false;
    this.fullScreen = this.globalService.isMobile;
    this.mainVolume = 1;
    this.currentComputedSequence = this.computedSequenceList[0];
  }

  public processBlocks(addSecond: boolean = true) {
    if (this.workout.playerSequence.length > 0 && !this.workoutFinished) {

      if (this.mainTimerRunning) {
        // Sumamos solo si no es llamada inicial
        if (addSecond) {
          this.mainTime++;
        }

        // BLOCKS
        // Activamos bloques cuando llega al initTime exacto
        this.workout.playerSequence.forEach(block => {
          // Si el initTime del block ya ha pasado y el initTime + duration es > que mainTime, añadimos block
          if (this.mainTime >= block.initTime && (this.mainTime <= block.initTime + block.duration)) {
            this.currentBlocks.length = 0; // solución temporal para tener solo un bloque a la vez
            this.startBlock(block);
          }
        });
        // Desactivamos bloques cuando lleguen a initTime + duration
        for (let i = this.currentBlocks.length - 1; i >= 0; i--) {
          if (this.mainTime >= this.currentBlocks[i].initTime + this.currentBlocks[i].duration) {
            // Borramos elemento
            this.currentBlocks.splice(i, 1);
          }
        }

        // MUSIC BLOCKS
        // Activamos bloques de musica cuando llega al initTime exacto
        if (this.workout.musicSequence) {
          this.workout.musicSequence.forEach(block => {
            if (this.mainTime >= block.initTime) {
              if (this.mainTime <= block.initTime + block.duration) {
                // Iniciamos musica solo si: se permite con musicChange, no hay musica (para que funcione el primero) o la musica ha cambiado (para cambiar a ejercicio de otra fase)
                if (block.item.audioChannel == 0 && (block.musicChange || this.currentMusicBlocks.length === 0 || block.item.id !== this.currentMusicBlocks[0].item.id)) {
                  this.startMusicBlock(block);
                }
              }
              // o iniciamos trigger
              if (block.item.audioChannel == 1 && this.mainTime <= block.initTime + 1 && this.currentTriggerBlocks.length === 0) {
                this.startTriggerBlock(block);
              }
            }
          });
        }


        // Desactivamos bloques de musica cuando lleguen a initTime + duration
        for (let i = this.currentMusicBlocks.length - 1; i >= 0; i--) {
          if (this.mainTime >= this.currentMusicBlocks[i].initTime + this.currentMusicBlocks[i].duration) {
            // Borramos elemento solo si se permite con musicChange
            if (this.currentMusicBlocks[i].musicChange) {
              this.currentMusicBlocks.splice(i, 1);
            }
          }
        }

        // Si no hay bloques de musica, pausamos musica
        if (this.currentMusicBlocks.length === 0) {
          this.mainMusic.pause();
        }

        // Desactivamos bloques de trigger cuando lleguen a initTime + duration
        for (let i = this.currentTriggerBlocks.length - 1; i >= 0; i--) {
          if (this.mainTime >= this.currentTriggerBlocks[i].initTime + 1) {
            this.currentTriggerBlocks.splice(i, 1);
          }
        }

        // Si no hay bloques de trigger, pausamos triggerChannel
        if (this.currentTriggerBlocks.length === 0) {
          //this.triggerChannel.pause();
        }

        // SESSION
        // Asignamos current Session
        if (this.mainTime >= this.getNextComputedSequence().initTime && (this.mainTime <= this.getNextComputedSequence().initTime + this.getNextComputedSequence().duration)) {
          this.currentComputedSequence = this.getNextComputedSequence();
        }

        if (this.mainTime < this.currentComputedSequence.initTime) {
          this.currentComputedSequence = this.getPreviousComputedSequence();
        }

        // CHECK FINISH
        // Comprobamos si ha acabado el workouts
        if (this.mainTime >= this.workout.duration) {
          this.workoutFinished = true;
          this.sendFinishDataToPramacloud();
        }

      }

      // Color de circulo REST
      if (this.currentBlocks[0]) {
        this.timeOuterCircleColor = this.currentBlocks[0].type === 1 ? '#000000' : this.restColor;
      }

      // Color de circulo de HR
      if (this.getBpmPercentage() >= 90) {
        this.HROuterCircleColor = constants.circleColors.HRColorDarkRed;
      }
      else if (this.getBpmPercentage() >= 80) {
        this.HROuterCircleColor = constants.circleColors.HRColorDarkYellow;
      }
      else if (this.getBpmPercentage() > 70) {
        this.HROuterCircleColor = constants.circleColors.HRColorDarkGreen;
      }
      else if (this.getBpmPercentage() > 60) {
        this.HROuterCircleColor = constants.circleColors.HRColorDarkBlue;
      }
      else {
        this.HROuterCircleColor = constants.circleColors.HRColorDarkGray;
      }

    }
    this.previousBlock = this.getPreviousStep();
    this.nextBlock = this.getNextStep();

  }

  private startBlock(block: WorkoutSequenceBlock) {
    // Si no existe el block en currentBlocks, lo añadimos
    if (this.currentBlocks.findIndex(item => item.id === block.id) === -1) {
      this.currentBlocks.push(block);
    }
  }
  private startMusicBlock(block: WorkoutSequenceBlock) {
    let indexPlaying = 0;
    // Si no existe el block en currentMusicBlocks, lo añadimos
    if (this.currentMusicBlocks.findIndex(item => item.id === block.id) === -1) {
      this.currentMusicBlocks.length = 0; // solucion temporal para un solo bloque
      this.currentMusicBlocks.push(block);

      // Desordenamos array de musica
      this.currentPlaylist = block.item.songs;
      this.currentPlaylist.sort((a, b) => 0.5 - Math.random());

      // Cargamos primera
      if (this.currentPlaylist.length > 0) {
        this.mainMusic.src = this.currentPlaylist[indexPlaying].path;
        this.mainMusic.load();
        this.mainMusic.volume = this.mainVolume;
        this.mainMusic.play();

        this.mainMusic.onended = () => {
          indexPlaying++;
          this.mainMusic.src = this.currentPlaylist[indexPlaying].path;
          this.mainMusic.load();
          this.mainMusic.play();
        };

      } else {
        this.mainMusic.pause();
      }
    }
  }

  private startTriggerBlock(block: WorkoutSequenceBlock) {
    // Si no existe el block en currentTriggerBlock, lo añadimos
    //if (this.currentTriggerBlocks.findIndex(item => item.id === block.id) === -1) {
    this.currentTriggerBlocks.length = 0; // solucion temporal para un solo bloque
    this.currentTriggerBlocks.push(block);

    this.currentPlaylist = block.item.songs;

    // Cargamos primera
    if (this.currentPlaylist.length > 0) {
      this.triggerChannel.src = this.currentPlaylist[0].path;
      this.triggerChannel.load();
      this.triggerChannel.volume = this.mainVolume;
      this.triggerChannel.play();

    } else {
      this.triggerChannel.pause();
    }
    //}
  }


  public pauseMainTimer() {
    this.mainTimerRunning = false;
    this.mainMusic.pause();
    this.triggerChannel.pause();
    this.events.doEvent({ key: 'workout-status', value: 'paused' });
  }

  public resumeMainTimer() {
    this.mainTimerRunning = true;
    this.mainMusic.play();
    this.triggerChannel.play();
    this.events.doEvent({ key: 'workout-status', value: 'playing' });
  }

  public goToNextPhase() {
    this.currentComputedSequence = this.getNextComputedSequence();
    this.mainTime = this.currentComputedSequence.initTime - 1;
    this.triggerChannel.pause();
    this.resumeMainTimer();
    this.processBlocks();
  }
  public goToPreviousPhase() {
    this.currentComputedSequence = this.getPreviousComputedSequence();
    this.mainTime = this.currentComputedSequence.initTime;
    this.triggerChannel.pause();
    this.resumeMainTimer();
    this.processBlocks();
  }

  public goToNextExercise() {
    this.mainTime = this.nextBlock.initTime - 1;
    this.triggerChannel.pause();
    this.resumeMainTimer();
    this.processBlocks();
  }
  public goToPreviousExercise() {
    this.mainTime = this.previousBlock.initTime;
    this.triggerChannel.pause();
    this.resumeMainTimer();
    this.processBlocks();
  }

  public quitAsk() {
    this.quitQuestion = true;
  }
  public quitWorkout() {
    // this.router.navigateByUrl('/workout/'+this.workout.id);
    this.resetWorkout();
    this.workout.playingWorkout = false;
  }
  public cancelQuit() {
    this.quitQuestion = false;
  }

  public getNextBlock() {
    // Devuelve el siguiente block para el [0] de currentBlocks
    const blockIndex = this.workout.playerSequence.findIndex(block => block.id === this.currentBlocks[0].id);
    if (blockIndex > -1 && this.workout.playerSequence.length > blockIndex + 1) {
      const nextBlock = this.workout.playerSequence[blockIndex + 1];
      return nextBlock;
    } else {
      return null;
    }
  }

  public getPreviousBlock() {
    // Devuelve el anterior block para el [0] de currentBlocks
    const blockIndex = this.workout.playerSequence.findIndex(block => block.id === this.currentBlocks[0].id);
    if (blockIndex > 0) {
      const prevBlock = this.workout.playerSequence[blockIndex - 1];
      return prevBlock;
    } else {
      return null;
    }
  }

  public getNextStep() {
    // Devuelve el siguiente block relevante (no countdowns o similares pero sí exercises o videos) para el [0] de currentBlocks
    if (this.currentBlocks[0] !== undefined) {
      const blockIndex = this.workout.playerSequence.findIndex(block => block !== undefined && block.id === this.currentBlocks[0].id);
      if (blockIndex > -1 && this.workout.playerSequence.length > blockIndex + 1) {

        for (let i = blockIndex + 1; i < this.workout.playerSequence.length; i++) {
          const nextBlock = this.workout.playerSequence[i];

          // 1 exercise, 2 video, 3 layer, 4 playlist, 5 mood
          if (nextBlock.type === 1 || nextBlock.type === 2) {
            return nextBlock;
          }
        }
      }
    }
    return null;
  }

  public getPreviousStep() {
    // Devuelve el anterior block relevante (no countdowns o similares pero sí exercises o videos) para el [0] de currentBlocks
    if (this.currentBlocks[0] !== undefined) {
      const blockIndex = this.workout.playerSequence.findIndex(block => block !== undefined && block.id === this.currentBlocks[0].id);
      if (blockIndex > 0) {

        for (let i = blockIndex - 1; i >= 0; i--) {
          const previousBlock = this.workout.playerSequence[i];

          // 1 exercise, 2 video, 3 layer, 4 playlist, 5 mood
          if (previousBlock.type === 1 || previousBlock.type === 2) {
            return previousBlock;
          }
        }
      }
    }
    return null;
  }

  public getTimeToEndCurrentExercise() {

    const currentExerciseBlock = this.currentBlocks.find(block => block.type === 1 || block.type === 2 || block.type === 3);

    if (currentExerciseBlock) {
      return (- this.mainTime + currentExerciseBlock.initTime) + currentExerciseBlock.duration;
    } else {
      return null;
    }

  }

  public getExerciseCompletedPercentage() {
    return 100 - ((this.currentBlocks[0].duration - this.getTimeToEndCurrentExercise()) * 100) / this.currentBlocks[0].duration;
  }

  public getRoundExercisesCompletedPercentage() {
    let calculatedProgress = Number((this.currentBlocks[0].nExercise.replace(/\D/g, '')));
    let unit = 1;

    // Si existe alguna letra en el string, aplicamos reduccion para poder mostrar bien el contador
    if (this.currentBlocks[0].nExercise.match(/\D/g) && this.currentBlocks[0].nExercise.match(/\D/g).length > 0) {
      const part = this.currentBlocks[0].nExercise.match(/\D/g)[0];
      switch (part) {
        case 'A': unit = 1 / 2; break;
        case 'B': unit = 0; break;
      }
      calculatedProgress = calculatedProgress - unit;
    } else {
      calculatedProgress = calculatedProgress;
    }

    // Eliminamos caracteres no numericos de nExercise
    return ((calculatedProgress) * 100) / this.currentBlocks[0].totalExercise;
  }

  public getRoundsCompletedPercentage() {
    return (Number((this.currentBlocks[0].nRound)) * 100) / this.currentBlocks[0].totalRounds;
  }

  // Formatea el label del slider mainTime
  public formatMainTimeSliderLabel(value: number) {
    return Math.floor(value / 60) + ' MIN';
  }

  // Devuelve la siguiente sesión (WORKOUT; COOLDOWN, etc) del computedSequenceList. Si no hay, devuelve FINISH
  public getNextComputedSequence() {
    const currentItemIndex = this.computedSequenceList.findIndex(item => item.name === this.currentComputedSequence.name);
    const finishSession: WorkoutTag = {
      name: 'FINISH',
      duration: 0,
      order: 0
    };
    return currentItemIndex + 1 < this.computedSequenceList.length ? this.computedSequenceList[currentItemIndex + 1] : finishSession;
  }

  // Devuelve la anterior sesión (WORKOUT; COOLDOWN, etc) del computedSequenceList. Si no hay, devuelve false
  public getPreviousComputedSequence() {
    const currentItemIndex = this.computedSequenceList.findIndex(item => item.name === this.currentComputedSequence.name);
    return currentItemIndex - 1 >= 0 ? this.computedSequenceList[currentItemIndex - 1] : this.computedSequenceList[0];
  }

  // Devuelve el tiempo restante de esta sesion
  public currentComputedSequenceRemainingTime() {
    return this.currentComputedSequence.initTime + this.currentComputedSequence.duration - this.mainTime;
  }

  // Activa/Desactiva modo pantalla completa
  public toggleFullScreen() {
    this.fullScreen = !this.fullScreen;
    if (this.fullScreen) {
      this.openFullscreen();
    } else {
      this.closeFullscreen();
    }
  }

  // Si presionamos ESC salimos de pantalla completa
  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.fullScreen = false;
    // this.closeFullscreen();
  }

  public getBpmPercentage() {
    let percentage = Math.round(this.heartRate.value * 100 / this.userMaxBpm);
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage;
  }

  public volumeChanged($event) {
    this.mainVolume = $event;
    this.mainMusic.volume = this.mainVolume * this.mainVolume;
    this.events.doEvent({ key: 'volume-changed', value: this.mainVolume });
  }

  /* View in fullscreen */
  private openFullscreen() {
    if (this.docElement.requestFullscreen) {
      this.docElement.requestFullscreen();
    }
  }

  /* Close fullscreen */
  private closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  public fullScreenExitHandler() {
    if (!document.fullscreenElement) {
      this.fullScreen = false;
    }
  }

  public sendInitDataToPramacloud() {
    //const userName = localStorage.getItem(constants.keyUsername);
    let sessionInfo = JSON.parse(localStorage.getItem(constants.keySession));
    let userId;
    if (sessionInfo) {
      userId = sessionInfo.userId ? sessionInfo.userId : 0;
    }

    this.sendDataObject = {
      id: 0,
      initDate: new Date().toISOString(),
      finishDate: null,
      idWorkout: this.workout.id,
      idUser: userId,
      idStatus: 0,
      lastTrackInfo: 0,
      idChallenge: 0
    }

    this.playerService.sendInitDataToPramacloud(this.sendDataObject).subscribe(
      result => {
        this.sendDataObject.id = result.id;
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public sendFinishDataToPramacloud() {
    //const userName = localStorage.getItem(constants.keyUsername);
    this.sendDataObject.finishDate = new Date().toISOString();
    this.sendDataObject.idStatus = 1;

    this.playerService.sendFinishDataToPramacloud(this.sendDataObject).subscribe(
      result => {
        console.log('Session finished - Data sent');
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  // getDeviceStatus() {
  //   this.deviceSubscription = this.bluetoothService.getDevice()
  //     .subscribe({
  //       next: this.deviceSelected.bind(this),
  //       error: this.hrHasError.bind(this),
  //     });
  // }

  // deviceSelected(device: BluetoothDevice) {
  //   this.heartRateDevice = device;
  // }

  // requestValue() {
  //   this.valuesSubscription = this.bluetoothService.value()
  //     .subscribe(data => null, error => this.hrHasError.bind(this));
  // }

  // hrUpdateValue(value: number) {
  //   this.heartRateValue = value;

  //   let sessionInfo = JSON.parse(localStorage.getItem(constants.keySession));
  //   let maxbpm = 0;

  //   if (sessionInfo) {
  //     maxbpm = sessionInfo.maxbpm;
  //   }

  //   if (!maxbpm || maxbpm == 0) {
  //     maxbpm = 187;
  //   }

  //   this.heartRatePercentage = Math.round(value * 100 / maxbpm);
  // }

  // hrHasError(error: string) {
  //   console.log(error);
  // }

  // disconnect() {
  //   this.bluetoothService.disconnectDevice();
  //   this.deviceSubscription.unsubscribe();
  //   this.valuesSubscription.unsubscribe();
  // }

}
